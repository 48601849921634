<template>
  <v-card class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-toolbar-title class="row justify-center">
        <span>Типы транспорта</span>
      </v-toolbar-title>
    </v-toolbar>
    <v-row no-gutters class="ma-3 justify-end">
      <v-btn :fab="$vuetify.breakpoint.xsOnly" :small="$vuetify.breakpoint.xsOnly" rounded @click="create"
             color="primary">
        <span class="font-weight-bold hidden-xs-only">Создать</span>
        <v-icon :right="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
      </v-btn>
    </v-row>
    <v-row class="mt-5 hidden-xs-only" dense>
      <v-col cols="1" class="row no-gutters justify-center">
        <span class="text-caption ml-4 font-weight-bold">Изображение</span>
      </v-col>
      <v-col cols="3" class="row no-gutters justify-center">
        <span class="text-caption ml-2 font-weight-bold">Название</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption font-weight-bold">Макс. дистанция</span>
      </v-col>
      <v-col cols="4" class="row no-gutters justify-center">
        <span class="text-caption font-weight-bold">Детали</span>
      </v-col>
      <v-col cols="2" class="row no-gutters justify-center">
        <span class="text-caption font-weight-bold">Редактировать</span>
      </v-col>
    </v-row>
    <v-row no-gutters v-for="v in list" :key="v.id" class="my-1 py-1 grey lighten-5">
      <v-col cols="12" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters justify-center d-flex align-center">
        <v-avatar v-if="v.icon" size="50px" class="grey lighten-2">
          <img alt="" :src="v.icon">
        </v-avatar>
      </v-col>
      <v-col cols="12" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters justify-center d-flex align-center">
        <span class="font-weight-bold">{{ v.name }}</span>
      </v-col>
      <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-center align-center d-flex">
        <span class="font-weight-bold">{{ v.max_distance }} км.</span>
      </v-col>
      <v-col cols="12" class="col col-lg-4 col-md-4 col-sm-4">
        <div class="d-flex justify-center body-2" v-for="f in v.fields" :key="f.key">
          {{ f.label }} :
        </div>
      </v-col>
      <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters justify-space-around align-center d-flex">
        <v-btn fab small>
          <v-icon color="primary" @click="edit(v.id)">mdi-pencil</v-icon>
        </v-btn>
        <v-btn fab small v-if="role === 'ADMIN'">
          <v-icon color="error" @click="del(v)">mdi-delete</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import {uuid} from "uuidv4";
import axios from "../services/axios";

export default {
  data: () => ({
    list: []
  }),

  created() {
    this.fetch();
  },

  computed: {
    role() {
      return this.$store.getters["auth/role"];
    },
  },

  methods: {
    fetch() {
      axios.send({
        url: "/vehicle/types",
        method: "GET"
      }).then(resp => {
        this.list = resp.data;
      });
    },
    create() {
      let id = uuid.Nil;
      this.$router.push({name: 'vehicleTypeEdit', params: {id}})
    },
    edit(id) {
      this.$router.push({name: 'vehicleTypeEdit', params: {id}})
    },
    del(item) {
      this.$confirm(
          "Вы точно хотите удалить тип транспорта",
          "Внимание",
          "question"
      ).then(() => {
        axios.send({
          url: "/vehicle/types",
          method: "DELETE",
          data: item
        }).then(() => {
          this.fetch();
        });
      });
    }
  }
};
</script>
